import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, Typography } from '@mui/material';
import { ShippingDeclaration } from '@usgm/inbox-api-types';
import { mailsShipmentUtils } from '@usgm/utils';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { webSiteUrl } from '../../../../../../../../../helpers/urlHelper';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';
import { DECLARATION_SCHEMA } from '../../../../MailInfo/ShippingDeclarationsManager';
import ActionButtons from '../../ActionButtons';
import { useShippingMailsDeclarations } from '../../hooks/useShippingMailsDeclarations';
import { mailsShipmentSlice, selectInsuranceAmount, selectInsureShipment } from '../../mailsShipmentSlice';
import InsureShipment from '../ShipperStep/InsureShipment';
import StepHeader from '../StepHeader';
import MailItem from './MailItem';

export const MAIL_DECLARATION_SCHEMA = z.object({
  mailId: z.number(),
  imageUrl: z.string(),
  declarations: z.array(DECLARATION_SCHEMA),
});

const mailsDeclarationSchema = z.object({
  mails: z.array(MAIL_DECLARATION_SCHEMA),
});

export type TMailsDeclarationSchemaType = z.infer<typeof mailsDeclarationSchema>;

function DeclarationsStep() {
  const dispatch = useAppDispatch();
  const mailDeclarations = useShippingMailsDeclarations();
  const insureShipment = useAppSelector(selectInsureShipment);
  const insuredAmount = useAppSelector(selectInsuranceAmount);

  const formMethods = useForm<TMailsDeclarationSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(mailsDeclarationSchema),
    defaultValues: mailDeclarations,
    criteriaMode: 'firstError',
  });
  const { control } = formMethods;

  useEffect(() => {
    dispatch(mailsShipmentSlice.actions.setIsMailDeclarationsValid(formMethods.formState.isValid));
  }, [dispatch, formMethods, formMethods.formState.isValid]);

  const handleNextClick = () => {
    dispatch(mailsShipmentSlice.actions.setMailsShippingDeclarations(formMethods.getValues()));
  };

  useEffect(() => {
    formMethods.trigger();
  }, [formMethods]);

  const { fields: mailsDeclarations } = useFieldArray({
    control,
    name: 'mails',
  });

  const allDeclarations = formMethods.watch('mails');

  const maxAmount = useMemo(() => {
    const declarations: ShippingDeclaration[] = [];
    allDeclarations.forEach((mail) => {
      declarations.push(...mail.declarations);
    });
    return mailsShipmentUtils.calculateDeclarationsTotal(declarations);
  }, [allDeclarations]);

  const insuredAmountIssue = insureShipment && maxAmount > 0 && maxAmount < insuredAmount;

  const canProceed = formMethods.formState.isValid && !insuredAmountIssue;

  return (
    <>
      <ContentContainer pt={0} pb={11.75}>
        <StepHeader />
        <Typography>
          We don't know what's in these packages Please list your items below. Declaration is to specify a value for the
          carrier should any damage or loss occur at Customs when shipping internationally{' '}
          <Link target="_blank" href={webSiteUrl('helps/can-i-write-my-own-declarations/')}>
            Learn more
          </Link>
        </Typography>
        <Box mt={4}>
          <FormProvider {...formMethods}>
            {mailsDeclarations.map((mail, index) => (
              <MailItem key={mail.mailId} fieldIndex={index} imageUrl={mail.imageUrl} mailId={mail.mailId} />
            ))}
          </FormProvider>
        </Box>
        <Box mt={4}>
          <InsureShipment maxAmount={maxAmount} />
        </Box>
      </ContentContainer>
      <ActionButtons
        onNextClick={handleNextClick}
        canProceed={canProceed}
        errorMessage={
          insuredAmountIssue
            ? mailsShipmentUtils.generateInsuredAmountError(maxAmount)
            : 'Please add declarations for all items to continue'
        }
      />
    </>
  );
}

export default React.memo(DeclarationsStep);
