import { Box, Typography, useTheme } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MAIL_DECLARATION_SCHEMA, TMailsDeclarationSchemaType } from '.';
import { AddButton, Button, FlexBox, ImageCell, Toast } from '@usgm/shared-ui';
import { DeclarationsRow, DEFAULT_DECLARATION } from '../../../../MailInfo/ShippingDeclarationsManager';
import { usePostMailDeclarationsMutation } from '../../../../../api';
import { inboxHelpers } from '@usgm/utils';

function MailItem({ fieldIndex, imageUrl, mailId }: { fieldIndex: number; mailId: number; imageUrl: string }) {
  const theme = useTheme();

  const [postDeclarationsMutation, postDeclarationsQuery] = usePostMailDeclarationsMutation();

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext<TMailsDeclarationSchemaType>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `mails.${fieldIndex}.declarations`,
  });

  const mailDeclarations = watch(`mails.${fieldIndex}.declarations`);

  const handleSaveDeclarations = async () => {
    await postDeclarationsMutation({
      declarations: mailDeclarations,
      mailId,
    });
  };

  const isValid = MAIL_DECLARATION_SCHEMA.safeParse({
    mailId,
    imageUrl,
    declarations: mailDeclarations,
  }).success;

  return (
    <Box mb={5}>
      <Typography mb={1} fontWeight={theme.typography.fontWeightBold} color={theme.palette.primary.main} variant="h6">
        Item #{mailId}
      </Typography>
      <ImageCell enablePreview height={452} imageUrl={imageUrl} />
      <Box mt={4}>
        {fields.map((field, index) => (
          <DeclarationsRow
            control={control}
            key={field.id}
            errors={{
              declarations: errors.mails?.[fieldIndex]?.declarations?.[index]?.description?.message,
              itemValue: errors.mails?.[fieldIndex]?.declarations?.[index]?.itemValue?.message,
              quantity: errors.mails?.[fieldIndex]?.declarations?.[index]?.quantity?.message,
            }}
            register={register}
            fieldsPath={`mails.${fieldIndex}.declarations.${index}`}
            index={index}
            remove={remove}
            showRemoveButton={fields.length > 1}
          />
        ))}
      </Box>
      <FlexBox minHeight={48} mt={1}>
        <AddButton
          label={fields.length > 0 ? 'Add another declaration' : 'Add a declaration'}
          onClick={() => append(DEFAULT_DECLARATION)}
        />
        {isValid && (
          <Button
            type="submit"
            isLoading={postDeclarationsQuery.isLoading}
            disabled={postDeclarationsQuery.isLoading}
            onClick={handleSaveDeclarations}
            variant="contained"
            size="small"
          >
            Save
          </Button>
        )}
      </FlexBox>
      {(postDeclarationsQuery.isError || postDeclarationsQuery.isSuccess) && (
        <Toast
          severity={postDeclarationsQuery.isError ? 'error' : 'success'}
          title={
            postDeclarationsQuery.isError
              ? inboxHelpers.GENERIC_ERROR_MESSAGE
              : 'Successfully submitted mail declarations.'
          }
        />
      )}
    </Box>
  );
}
export default MailItem;
