import { ReactNode } from 'react';

import { Autocomplete } from '@mui/material';
import { TextInput } from '@usgm/shared-ui';

import { Controller, FieldPath, FieldValues, PathValue, useFormContext } from 'react-hook-form';

export function ControlledAutoComplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TFieldValue = PathValue<TFieldValues, TName>,
>({
  name,
  handleChange,
  getOptionLabel,
  isOptionEqualToValue,
  label,
  placeholder,
  options,
  defaultValue,
  renderOption,
  freeSolo,
  inputValueTransformer,
}: {
  name: TName;
  handleChange?: (value: TFieldValue | TFieldValue[] | null) => void;
  getOptionLabel: (option: TFieldValue) => string;
  isOptionEqualToValue: (option: TFieldValue, value: TFieldValue) => boolean;
  label?: string;
  placeholder?: string;
  options: TFieldValue[];
  defaultValue: unknown;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, val: TFieldValue) => ReactNode;
  freeSolo?: boolean;
  inputValueTransformer?: (inputValue: string) => TFieldValue;
}) {
  const { control } = useFormContext<TFieldValues>();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as PathValue<TFieldValues, TName>}
      render={({ field: { onChange, ...props }, fieldState }) => (
        <Autocomplete
          {...props}
          ref={props.ref}
          defaultValue={null}
          onChange={(_, newValue) => {
            handleChange?.(newValue);
            onChange(newValue);
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          disablePortal
          fullWidth
          freeSolo={freeSolo}
          onInputChange={(_, newValue) => {
            const transformedValue = inputValueTransformer?.(newValue);
            if (freeSolo && transformedValue) {
              handleChange?.(transformedValue);
              onChange(transformedValue);
            }
          }}
          renderOption={renderOption}
          options={options}
          renderInput={({ InputLabelProps, ...params }) => (
            <TextInput
              {...params}
              autoComplete="off"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              required
              InputLabelProps={{ ...InputLabelProps, shrink: true }}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      )}
    />
  );
}
