import { CircularProgress, styled, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { BaseScan } from '@usgm/inbox-api-types';
import { Button, ConfirmationDialog, FlexBox, Tooltip, useDialog, useMobileMode } from '@usgm/shared-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import ReCaptchaV2 from '../../../../../../captcha/ReCaptchaV2';
import { useRecaptchaRequest } from '../../../../../../hooks/useRecaptchaRequest';
import { SETTINGS_PATHS } from '../../../settings/paths';

import { CaptchaType } from '@inbox/constants';

import { useBatchExportScansMutation } from '../../../../inboxAccountsApi';
import { useBulkDeleteScansMutation } from '../../api';

import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';

export type ScansBulkActionsProps = {
  scans: GridRowParams<BaseScan>[];
  allScans?: boolean;
  clearSelection?: () => void;
};

const StyledRouterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const createErrorMessage = ({ action, label }: { action: string; label: string }) => {
  return `Apologies, we encountered an issue while attempting to ${action} the ${label}. Please try again, and if the problem persists, contact support for assistance.`;
};

function ScansBulkActions({ scans, allScans, clearSelection }: ScansBulkActionsProps) {
  const dispatch = useAppDispatch();
  const downloadConfirmationDialog = useDialog();
  const deleteConfirmationDialog = useDialog();
  const isMobile = useMobileMode();

  const [batchExportScansMutation] = useBatchExportScansMutation();
  const [bulkDeleteScansMutation, bulkDeleteQuery] = useBulkDeleteScansMutation();

  const { isLoading, makeRequest, requestId } = useRecaptchaRequest({
    action: CaptchaType.EcportScans,
    apiRequest: batchExportScansMutation,
  });

  const handleExportConfirmation = async () => {
    const response = await makeRequest({
      ids: allScans ? 'all' : scans.map((scan) => scan.row.uuid),
    });
    const hasError = !response || 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? createErrorMessage({ action: 'export', label: itemsLabel })
          : 'Export has been initiated and it’s in progress now.',
      }),
    );
    if (!hasError) {
      clearSelection?.();
    }
  };
  const handleDeleteConfirmation = async () => {
    const response = await bulkDeleteScansMutation({ scanIds: allScans ? 'all' : scans.map((scan) => scan.row.uuid) });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? createErrorMessage({ action: 'delete', label: itemsLabel })
          : `${scans.length > 1 || allScans ? 'Scans have' : 'Scan has'} been successfully deleted.`,
      }),
    );
    if (!hasError) {
      clearSelection?.();
    }
  };
  const itemsLabel = scans.length > 1 ? 'scans' : 'scan';

  return (
    <FlexBox gap={isMobile ? 1 : 2}>
      <Tooltip title="Download Selected Item(s)">
        <Button disabled={isLoading} onClick={downloadConfirmationDialog.openDialog} variant="contained">
          {isLoading ? <CircularProgress size={20} /> : 'Download'}
        </Button>
      </Tooltip>
      <ReCaptchaV2 requestId={requestId} />
      <Tooltip title="Delete Selected Item(s)">
        <Button disabled={bulkDeleteQuery.isLoading} onClick={deleteConfirmationDialog.openDialog} variant="outlined">
          {bulkDeleteQuery.isLoading ? <CircularProgress /> : 'Delete'}
        </Button>
      </Tooltip>
      <ConfirmationDialog
        open={deleteConfirmationDialog.open}
        onClose={deleteConfirmationDialog.closeDialog}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDeleteConfirmation}
        content={
          <>
            <Typography mb={2} textAlign="center" variant="h5">
              Please confirm!
            </Typography>
            <Typography textAlign="center">Are you sure you want to delete selected {itemsLabel}.</Typography>
          </>
        }
      />
      <ConfirmationDialog
        open={downloadConfirmationDialog.open}
        onClose={downloadConfirmationDialog.closeDialog}
        confirmText="Export"
        cancelText="Cancel"
        onConfirm={handleExportConfirmation}
        content={
          <>
            <Typography mb={2} textAlign="center" variant="h5">
              Do you want to export selected {itemsLabel} in a zip file?
            </Typography>
            <Typography textAlign="center">
              Your exports will be available under Exports in{' '}
              <StyledRouterLink to={SETTINGS_PATHS.EXPORTS}>"Settings &gt; Exports".</StyledRouterLink> You will get an
              email notification once the export is completed.
            </Typography>
          </>
        }
      />
    </FlexBox>
  );
}

export default ScansBulkActions;
