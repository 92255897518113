import { Box, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { FlexBox, Inbox, UI_CONSTANTS } from '@usgm/shared-ui';
import { VerifyAccountSteps, VerifyAccountStepsProps } from '../components/VerifyAccountSteps';

import { AccountVerificationStepStatus } from '@usgm/inbox-api-types';

import { useMemo } from 'react';
import { useAuth } from '../../../../auth/hooks/useAuth';
import welcomeImgSrc from '../assets/welcome.png';
import { ONBOARDING_STEPS } from '../config/onboardingStepsMapper';
import { useOnboardingData } from '../hooks/useOnboardingData';
import { inboxHelpers } from '@usgm/utils';

const WelcomeImage = styled('img')(({ theme }) => ({
  maxWidth: '320px',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
  },
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    maxWidth: '100%',
  },
}));

const OnboardingStepsCard = styled(Inbox.Card)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

function WelcomeHeaderCard({ name }: { name?: string }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Inbox.Card>
      <FlexBox flexDirection={isMobile ? 'column' : 'row'}>
        <Box flex="1 1 auto">
          {name && (
            <Typography color={theme.palette.primary.dark} variant="h4">
              Welcome {name}!
            </Typography>
          )}
          <Typography mt={2} color={theme.palette.text.secondary}>
            We take care of your mail, so you can take care of what truly matters in life, and business.
          </Typography>
        </Box>
        <WelcomeImage src={welcomeImgSrc} alt="Welcome!" />
      </FlexBox>
    </Inbox.Card>
  );
}

export default function WelcomePage() {
  const { user } = useAuth();
  const { isLoading, accountVerificationState } = useOnboardingData(true);

  const steps: VerifyAccountStepsProps['items'] = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return ONBOARDING_STEPS.map(({ stepKey, getContent, getActions, title }, index) => {
      const skipped =
        stepKey !== 'signedUp'
          ? accountVerificationState?.steps[stepKey]?.status === AccountVerificationStepStatus.Skipped
          : false;
      const completed =
        stepKey !== 'signedUp'
          ? accountVerificationState?.steps[stepKey]?.status === AccountVerificationStepStatus.Completed
          : true;
      return {
        skipped,
        completed,
        title: title,
        content: getContent({ skipped, completed }),
        actions: getActions?.({ skipped, completed }),
        stepId: index + 1,
      };
    });
  }, [accountVerificationState?.steps, isLoading]);

  return (
    <Inbox.PageContainer enableMobileSpacings>
      <WelcomeHeaderCard name={user?.name} />
      <OnboardingStepsCard mt={2}>
        <Typography variant="subtitle2">
          Unlock all features of your virtual mailbox by finishing these steps
        </Typography>
        <VerifyAccountSteps items={steps} />
      </OnboardingStepsCard>
    </Inbox.PageContainer>
  );
}
