import { FormControlLabel, FormHelperText, Typography, useTheme } from '@mui/material';
import { StyledFormControl } from './styled/StyledFormControl';

import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { mailsShipmentSlice, selectIsExpediteShipping } from '../../mailsShipmentSlice';
import { inboxHelpers } from '@usgm/utils';
import { Checkbox } from '@usgm/shared-ui';

function ExpediteShippingControl({ expeditePrice, hasError }: { expeditePrice?: number | string; hasError?: boolean }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isExpediteShipping = useAppSelector(selectIsExpediteShipping);

  const handleChange = () => {
    dispatch(mailsShipmentSlice.actions.setIsExpediteShipping(!isExpediteShipping));
  };

  return (
    <StyledFormControl>
      <FormControlLabel
        value={isExpediteShipping}
        defaultChecked={isExpediteShipping}
        onChange={handleChange}
        control={<Checkbox hasError={hasError} />}
        label={
          <>
            <Typography mr={1} fontWeight={theme.typography.fontWeightBold} component="span">
              Expedite shipping to ship today
            </Typography>
            {expeditePrice && (
              <Typography color={theme.customColors.dark[300]} component="span">
                ({inboxHelpers.formatPrice(Number(expeditePrice))} add on)
              </Typography>
            )}
          </>
        }
      />
      {hasError && (
        <FormHelperText sx={{ padding: 0 }} error>
          Select Expedited to ship today or update ship date to the next business day.
        </FormHelperText>
      )}
    </StyledFormControl>
  );
}

export default ExpediteShippingControl;
