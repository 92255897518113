import { type Theme } from '@mui/material';
import { z } from 'zod';

export const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 8,
  RULES: [
    {
      rule: /.*[a-z].*/,
      error: 'Password must contain at least one lowercase letter',
    },
    {
      rule: /.*[A-Z].*/,
      error: 'Password must contain at least one uppercase letter',
    },
    {
      rule: /.*[0-9].*/,
      error: 'Password must contain at least one number',
    },
    {
      rule: /.*[!@#$%^&*].*/,
      optional: true,
      error: 'Password must contain at least one special character',
    },
  ],

  MESSAGE: `Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number.`,
  LABELS: [
    { label: 'Very Weak', getColor: (theme: Theme) => theme.palette.error.main },
    { label: 'Weak', getColor: (theme: Theme) => theme.palette.warning.main },
    { label: 'Good', getColor: (theme: Theme) => theme.palette.info.main },
    { label: 'Strong', getColor: (theme: Theme) => theme.palette.success.main },
    { label: 'Very Strong', getColor: (theme: Theme) => theme.palette.success.dark },
  ],
};

const REQUIRED_PASSWORD_RULES = PASSWORD_REQUIREMENTS.RULES.filter((rule) => !rule.optional);

export const PASSWORD_SCHEMA = z
  .string()
  .min(1, 'Password is required')
  .superRefine((value, ctx) => {
    const missingRule = REQUIRED_PASSWORD_RULES.find((rule) => !rule.rule.test(value));
    if (missingRule) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: missingRule.error,
      });
    }
    if (value.length < PASSWORD_REQUIREMENTS.MIN_LENGTH) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Password must be at least ${PASSWORD_REQUIREMENTS.MIN_LENGTH} characters long`,
      });
    }
  });
