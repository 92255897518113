import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import { ALL_COUNTRIES } from './config';

const FEATURE_NAME = 'COUNTRIES';

export type TCountry = {
  code: string;
  name: string;
  id: string;
};

const PRIORITY_COUNTRIES = ['US', 'CA'];

const countriesAdapter = createEntityAdapter<TCountry>({
  sortComparer: (a, b) => {
    const aPriorityIndex = PRIORITY_COUNTRIES.indexOf(a.code);
    const bPriorityIndex = PRIORITY_COUNTRIES.indexOf(b.code);

    if (aPriorityIndex !== -1 && bPriorityIndex === -1) {
      return -1;
    }
    if (aPriorityIndex === -1 && bPriorityIndex !== -1) {
      return 1;
    }
    if (aPriorityIndex !== -1 && bPriorityIndex !== -1) {
      return aPriorityIndex - bPriorityIndex;
    }

    return a.name.localeCompare(b.name);
  },
});

const emptyState = countriesAdapter.getInitialState();
const initialState = countriesAdapter.upsertMany(
  emptyState,
  Object.entries(ALL_COUNTRIES).map(([code, name]) => ({ code, name, id: code })),
);

export const countriesSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {},
});

const selectState = (state: RootState) => state[FEATURE_NAME];

export const countrySelectors = countriesAdapter.getSelectors(selectState);
