import { Address } from '@usgm/inbox-api-types';

const createAddNonEmptiesToArray =
  <T>(arr: T[]) =>
  (item: T | undefined) => {
    if (item) {
      arr.push(item);
    }
  };

export const stringifyAddress = ({
  addr,
  boxNumber,
  separator,
}: {
  addr: Partial<Address>;
  boxNumber?: number;
  separator?: string;
}) => {
  if (!addr) return '';
  const parts: string[] = [];

  const addNonEmptyParts = createAddNonEmptiesToArray(parts);
  if (addr.addressLine) {
    const addressLine = `${addr.addressLine}${boxNumber ? ` PMB ${boxNumber}` : ''}`;
    parts.push(addressLine);
    if (!boxNumber) {
      [addr.addressLine2, addr.addressLine3].forEach(addNonEmptyParts);
    }
  }
  [addr.city, addr.state, addr.postalCode].forEach(addNonEmptyParts);
  return parts.join(separator || ', ');
};
