import { PRODUCTION_URL } from '@inbox/constants';
import { inboxHelpers } from '@usgm/utils';
import { Helmet } from 'react-helmet-async';

const APP_NAME = 'US Global Mail';

export function HTMLHeadMetaTags({ title, description, imageUrl, canonicalUrl, useTitleTemplate = true, robotsTagContent }: inboxHelpers.GenericMetaInfo) {
  return (
    <Helmet titleTemplate={useTitleTemplate ? `%s | ${APP_NAME}` : undefined} defaultTitle={APP_NAME}>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <link rel="canonical" href={canonicalUrl || PRODUCTION_URL} />
      {robotsTagContent && <meta name="robots" content={robotsTagContent} />}
    </Helmet>
  );
}
