import { LOGO_URL, PRODUCTION_URL } from '@inbox/constants';
import { APP_PATHS, MAIN_PATHS } from './paths';

export type GenericMetaInfo = {
  title: string;
  description?: string;
  imageUrl?: string;
  canonicalUrl?: string;
  useTitleTemplate?: boolean;
  robotsTagContent?: string;
};

export type PathMetaInfoMapItem = GenericMetaInfo & {
  prefixMatch?: boolean;
};

function generateMetaTagInfo({
  useDefaultImage,
  path,
}: {
  useDefaultImage?: boolean;
  path?: string;
}): Partial<GenericMetaInfo> {
  return {
    ...(useDefaultImage ? { imageUrl: LOGO_URL } : {}),
    ...(path ? { canonicalUrl: `${PRODUCTION_URL}${path}` } : {}),
  };
}

export const PathMetaInfoMap: { [key: string]: PathMetaInfoMapItem } = {
  [MAIN_PATHS.SELECT_VIRTUAL_ADDRESS]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.SELECT_VIRTUAL_ADDRESS }),
    title: 'Find a Virtual Business Address Near You',
    description:
      'Find a virtual business address near you or create a business address in another state. Use our interactive map, search, or browse all listings.',
  },
  [MAIN_PATHS.SELECT_PLAN]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.SELECT_PLAN }),
    title: 'Affordable Virtual Mailbox Plans & Pricing',
    description:
      'Compare our virtual mailbox plans and pricing. Easily compare the different features and costs of our affordable Business, Plus and Basic plans.',
    prefixMatch: true,
  },
  [MAIN_PATHS.LOGIN]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.LOGIN }),
    title: 'US Global Mail Login for Access to Your Virtual Mailbox',
    useTitleTemplate: false,
    description:
      'Login into your US Global Mail virtual mailbox account and get instant access to easily ship, deposit checks, and read mail online. Get Started!',
  },
  [MAIN_PATHS.FORGOT_PASSWORD]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.FORGOT_PASSWORD }),
    title: 'US Global Mail Password Reset - Forgot Password',
    useTitleTemplate: false,
    description:
      'Reset your password for your US Global Mail account and get access to your mailbox, account settings and more.',
    robotsTagContent: 'noindex, nofollow',
  },
  [MAIN_PATHS.CREATE_ACCOUNT]: { title: 'Create Account' },
  [MAIN_PATHS.ACCEPT_TEAM_MEMBER_INVITATION]: { title: 'Accept Invitation' },
  [MAIN_PATHS.CHECKOUT]: { title: 'Checkout' },
  [MAIN_PATHS.MAINTENANCE]: { title: 'Maintenance' },
  [MAIN_PATHS.UNSUBSCRIBE]: { title: 'Unsubscribe' },
  [MAIN_PATHS.WELCOME]: { title: 'Welcome' },
  [MAIN_PATHS.RESET_PASSWORD]: { title: 'Reset Password' },
  [MAIN_PATHS.VERIFY_RESET_PASSWORD_TOKEN]: { title: 'Verify Token' },
  [`${MAIN_PATHS.APP}/${APP_PATHS.VERIFY_ACCOUNT}`]: { title: 'Verify Account', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.INBOX}`]: { title: 'Inbox', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SCANS}`]: { title: 'Scans', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SHIPMENTS}`]: { title: 'Shipments', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.BILLING_HISTORY}`]: { title: 'Billing History', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.DISCARDS}`]: { title: 'Discards' },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SETTINGS}`]: { title: 'Settings', prefixMatch: true },
};
