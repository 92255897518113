import { useCallback } from 'react';

export function useElementScrollIntoView(
  elementId: string,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' },
) {
  const elementScrollIntoView = useCallback(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView(options);
    }
  }, [elementId, options]);

  return elementScrollIntoView;
}
