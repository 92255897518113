import { Typography, TypographyProps, styled } from '@mui/material';
import React, { ComponentType, ReactNode } from 'react';
import { Tooltip, TooltipProps } from './Tooltip';

type StyleProps = {
  underline?: boolean;
  color?: string;
  fontWeight?: number | string;
};

export type InfoTitleProps = Omit<TooltipProps, 'children'> & {
  title: string;
  description: ReactNode;
  maxWidth?: number | string;
  icon?: React.ReactNode;
} & StyleProps;

type TStyledText = ComponentType<TypographyProps<'span', { component?: 'span' }> & StyleProps>;

const StyledText: TStyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'underline' && prop !== 'color' && prop !== 'fontWeight',
})<StyleProps>(({ theme, color = theme.palette.primary.main, underline = true, fontWeight = 500 }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color,
  borderBottom: underline ? `1px dashed ${color}` : 'none',
  fontWeight: fontWeight,
  '& svg': {
    marginLeft: theme.spacing(0.5),
  },
}));

export function InfoTitle({ icon, title, description, color, fontWeight, underline, ...tooltipProps }: InfoTitleProps) {
  return (
    <Tooltip {...tooltipProps} title={description} arrow>
      <StyledText component="span" fontWeight={fontWeight} underline={underline} color={color}>
        {title}
        {icon}
      </StyledText>
    </Tooltip>
  );
}

export default InfoTitle;
