import { Button, List, ListItem, styled, Theme, useTheme } from '@mui/material';
import { forwardRef, useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import { type DtoWarehouse } from '@usgm/inbox-api-types';
import { ScrollableArea, UI_HELPERS, useMobileMode } from '@usgm/shared-ui';

import { useAppDispatch, useAppSelector } from '../../../../store/index';
import {
  addressPickerSlice,
  selectActiveWarehouseId,
  selectFilteredWarehousesIds,
  warehousesSelectors,
} from '../../addressPickerSlice';

import { useAuth } from '../../../auth/hooks/useAuth';
import { usePaginateWarehouses } from '../../hooks/usePaginateWarehouses';
import { useSetWarehouse } from '../../hooks/useSetWarehouse';
import WarehouseInnerCard from '../WarehouseCard/WarehouseInnerCard';

const StyledList = styled(List)(({ theme }) => ({
  padding: '8px 8px 0px 0px',
  '& li::first-of-type': {
    paddingTop: 0,
  },
}));

const createActiveStyles = (theme: Theme) => ({
  overflow: 'hidden',
  borderRadius: `0px 0px ${theme.shape.borderRadius * 4}px ${theme.shape.borderRadius * 4}px`,
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.primary.main,
  },
});

const StyledLIstItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    position: 'relative',
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&:hover': createActiveStyles(theme),
    ...(isActive && createActiveStyles(theme)),
  }),
);

const WarehouseItem = forwardRef<HTMLLIElement, { id: DtoWarehouse['id']; handleSelection?: () => void }>(function (
  { id, handleSelection },
  ref,
) {
  const dispatch = useAppDispatch();
  const warehouse = useAppSelector((state) => warehousesSelectors.selectById(state, id));
  const onWarehouseSelect = useSetWarehouse(handleSelection);

  const handleClick = () => {
    dispatch(addressPickerSlice.actions.setActiveWarehouseId(warehouse.id));
  };

  return warehouse ? (
    <StyledLIstItem ref={ref} id={`warehouse-card-${id}`} isActive={warehouse.active} onClick={handleClick}>
      <WarehouseInnerCard {...warehouse} onSelect={onWarehouseSelect} />
    </StyledLIstItem>
  ) : null;
});

const SCROLL_BAR_WIDTH = 9;

const StyledScrollableArea = styled(ScrollableArea)(({ theme }) => {
  const maskHeight = theme.spacing(4);
  return {
    '--scrollbar-width': `${SCROLL_BAR_WIDTH}px`,
    '--mask-height': maskHeight,
    paddingBottom: maskHeight,
    '--mask-image-content': `linear-gradient(
    to bottom,
    transparent,
    black ${maskHeight},
    black calc(100% - ${maskHeight}),
    transparent
  )`,
    '--mask-size-content': `calc(100% - ${SCROLL_BAR_WIDTH}px) 100%`,
    '--mask-image-scrollbar': 'linear-gradient(black, black)',
    '--mask-size-scrollbar': `${SCROLL_BAR_WIDTH}px 100%`,
    maskImage: 'var(--mask-image-content), var(--mask-image-scrollbar)',
    maskSize: 'var(--mask-size-content), var(--mask-size-scrollbar)',
    maskPosition: '0 0, 100% 0',
    maskRepeat: 'no-repeat, no-repeat',
  };
});

export default function ScrollableWarehouseList({
  handleSelection,
  changeWarehouseMode,
}: {
  handleSelection?: (step?: number) => void;
  changeWarehouseMode?: boolean;
}) {
  const activeWarehouseId = useAppSelector(selectActiveWarehouseId);
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const theme = useTheme();
  const isMobile = useMobileMode();
  const warehouseIds = useAppSelector((state) =>
    selectFilteredWarehousesIds(state, {
      paginate: isMobile,
      skipRecommended: isMobile,
    }),
  );
  const { user } = useAuth();
  const warehouseId = user?.warehouseId;

  const { loadMore, canLoadMore } = usePaginateWarehouses();

  const containerStyles = useMemo(() => {
    if (isMobile) {
      return { height: 'auto' };
    } else {
      return { overflowY: 'auto', height: '100%' };
    }
  }, [isMobile]);

  const setListELementRef = useCallback(
    (index: number) => (el: HTMLLIElement | null) => {
      if (el) {
        itemsRef.current[index] = el;
      }
    },
    [],
  );

  useLayoutEffect(() => {
    const activeElementIndex = warehouseIds.findIndex((id) => id === activeWarehouseId);
    if (activeElementIndex !== -1 && scrollRef.current && itemsRef.current[activeElementIndex]) {
      UI_HELPERS.scrollToElement(scrollRef.current, itemsRef.current[activeElementIndex]);
    }
  }, [activeWarehouseId, warehouseIds]);

  return (
    <StyledScrollableArea
      ref={scrollRef}
      thumbSize={SCROLL_BAR_WIDTH}
      thumbColor={theme.palette.grey[300]}
      sx={containerStyles}
    >
      <StyledList>
        {warehouseIds.map((id, index) => {
          const isCurrent = id === warehouseId && changeWarehouseMode;
          if (isCurrent) {
            return false;
          }
          return <WarehouseItem ref={setListELementRef(index)} key={id} id={id} handleSelection={handleSelection} />;
        })}
      </StyledList>
      {canLoadMore && (
        <Button sx={{ mt: 3 }} variant="outlined" fullWidth onClick={loadMore}>
          Load More
        </Button>
      )}
    </StyledScrollableArea>
  );
}
