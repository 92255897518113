import { HTMLHeadMetaTags } from '@usgm/shared-ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { inboxHelpers } from '@usgm/utils';

function renderMetaTags(metaInfo: inboxHelpers.GenericMetaInfo | null) {
  if (!metaInfo) {
    return null;
  }
  return (
    <HTMLHeadMetaTags
      title={metaInfo.title}
      description={metaInfo.description}
      imageUrl={metaInfo.imageUrl}
      canonicalUrl={metaInfo.canonicalUrl}
      useTitleTemplate={metaInfo.useTitleTemplate}
      robotsTagContent={metaInfo.robotsTagContent}
    />
  );
}

function useMetaTags() {
  const { pathname } = useLocation();

  const metaInfo = useMemo(() => {
    const exactMatch = inboxHelpers.PathMetaInfoMap[pathname];
    if (exactMatch) {
      return exactMatch;
    }
    for (const path in inboxHelpers.PathMetaInfoMap) {
      const item = inboxHelpers.PathMetaInfoMap[path];
      if (item.prefixMatch && pathname.indexOf(path) === 0) {
        return item;
      }
    }
    return null;
  }, [pathname]);

  return { renderMetaTags: renderMetaTags(metaInfo), metaInfo };
}

export default useMetaTags;
