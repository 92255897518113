import { useEffect } from 'react';

import { Box, Typography, styled, useMediaQuery } from '@mui/material';

import { AccountRelation, DtoSubscriptionPlan } from '@usgm/inbox-api-types';

import { AddButton, Button, ConfirmationDialog, FlexBox, RemoveButton, Toast, useDialog } from '@usgm/shared-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { inboxHelpers, onboardingUtils } from '@usgm/utils';

import { useDeleteSecondaryAccountByIdMutation } from '../../api';
import UpgradePlanMessage from '../UpgradePlanMessage';
import AddNameFormRow from './AddNameFormRow';
import { DEFAULT_SELECTED_VALUE } from './constants';

import { webSiteUrl } from '../../../../../../helpers/urlHelper';
import { useAuth } from '../../../../../auth/hooks/useAuth';

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3),
  },
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(0, 2.5),
  },
}));

export enum AddNamesFormLayout {
  Standard = 'standard',
  Business = 'business',
}

export function AddNamesForm({
  showBusinessOptions = false,
  activePlan,
  layout = AddNamesFormLayout.Business,
  isSubmitting = false,
  showAddButton = true,
  fieldsFilter,
  hideUpgradeMessage = false,
  currentTotal,
}: {
  showBusinessOptions?: boolean;
  isSubmitting?: boolean;
  layout?: AddNamesFormLayout;
  activePlan: DtoSubscriptionPlan;
  showAddButton?: boolean;
  fieldsFilter?: (field: onboardingUtils.AccountSchemaType) => boolean;
  hideUpgradeMessage?: boolean;
  currentTotal?: number;
}) {
  const { user } = useAuth();
  const { closeDialog: closeLimitDialog, open: limitDialogOpen, openDialog: openLimitDialog } = useDialog();

  const { closeDialog, open, openDialog, value: accountIdToDelete } = useDialog<string>();
  const isDownMobileLandScape = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);

  const [deleteAccountMutation, { error: accountDeletionError }] = useDeleteSecondaryAccountByIdMutation();

  const methods = useFormContext<onboardingUtils.AccountNamesFormSchemaType | onboardingUtils.CompanyInfoFormSchema>();

  const {
    control,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    name: 'accountNames',
    control,
  });

  const handleRemove =
    ({ index, uuid }: { index: number; uuid?: string }) =>
    () => {
      if (!uuid) {
        remove(index);
      } else {
        openDialog(uuid);
      }
    };

  const handleClickAddName = () => {
    if (fields.length >= inboxHelpers.MAX_ACCOUNTS_LIMIT) {
      openLimitDialog();
      return;
    }
    const defaultRowData = {
      name: '',
      relationType: showBusinessOptions ? AccountRelation.Company : DEFAULT_SELECTED_VALUE,
      uuid: '',
    };
    const rowData = showBusinessOptions
      ? {
          ...defaultRowData,
          city: '',
          state: '',
          country: null,
          zipCode: '',
          streetAddress: '',
          placeOfRegistration: '',
          typeOfBusiness: '',
        }
      : defaultRowData;

    append({ ...rowData } as unknown as onboardingUtils.AccountSchemaType);
  };

  const handleDeleteConfirmation = async () => {
    if (accountIdToDelete) {
      const fieldIndex = fields.findIndex((field) => field.uuid === accountIdToDelete);
      const field = fields[fieldIndex];
      remove(fieldIndex);
      const response = await deleteAccountMutation({ userId: accountIdToDelete });
      if ('error' in response) {
        // if there is an error, add the field back
        append(field);
      }

      closeDialog();
    }
  };

  const handleContactUsClick = () => {
    window.open(webSiteUrl('contact-us'), '_blank');
  };

  const FormContainerComponent = layout === AddNamesFormLayout.Standard ? FormContainer : Box;
  const fieldsWithIndexes: Array<onboardingUtils.AccountSchemaType & { index: number }> = fields.map(
    (field, index) => ({
      ...field,
      index,
    }),
  );
  const filteredFields = fieldsFilter ? fieldsWithIndexes.filter(fieldsFilter) : fieldsWithIndexes;

  useEffect(() => {
    if (errors?.accountNames?.length) {
      const firstErrorKey = Object.keys(errors.accountNames);
      const elem = document.getElementById(`add-name-row-${firstErrorKey[0]}`);
      elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);

  return (
    <>
      <FormContainerComponent>
        <FlexBox flexDirection="column" gap={isDownMobileLandScape ? 4 : 1} mb={isDownMobileLandScape ? 4 : 0}>
          {filteredFields.map(({ name, relationType, uuid, index }) => {
            const disableRemove = relationType === AccountRelation.Primary && user?.userUUID === uuid;

            return (
              <FlexBox
                id={`add-name-row-${index}`}
                key={index}
                flexDirection={isDownMobileLandScape ? 'column' : 'row'}
                alignItems="flex-start"
                width="100%"
              >
                <AddNameFormRow
                  isBusiness={showBusinessOptions}
                  disabled={isSubmitting}
                  index={index}
                  disableRelationType={relationType === AccountRelation.Primary}
                  name={name}
                  relationType={relationType}
                  uuid={uuid}
                />
                {!isDownMobileLandScape ? (
                  !disableRemove ? (
                    <Box marginLeft={1.5} marginTop={1.75}>
                      <RemoveButton onClick={isSubmitting ? undefined : handleRemove({ uuid, index })} />
                    </Box>
                  ) : (
                    <Box marginLeft={4.5} />
                  )
                ) : (
                  !disableRemove && (
                    <FlexBox width="100%" justifyContent="flex-end">
                      <Button
                        onClick={handleRemove({ uuid, index })}
                        disabled={isSubmitting}
                        size="small"
                        variant="outlined"
                        color="error"
                      >
                        Remove
                      </Button>
                    </FlexBox>
                  )
                )}
              </FlexBox>
            );
          })}
        </FlexBox>
        {showAddButton && (
          <AddButton
            label={`Add ${filteredFields.length > 0 ? 'another' : 'a'} ${showBusinessOptions ? `company` : 'name'}`}
            onClick={handleClickAddName}
          />
        )}
        {!hideUpgradeMessage && <UpgradePlanMessage currentTotal={currentTotal || fields.length} plan={activePlan} />}
      </FormContainerComponent>

      {accountDeletionError && <Toast title={accountDeletionError?.message} severity="error" />}

      <ConfirmationDialog
        onClose={closeLimitDialog}
        onConfirm={handleContactUsClick}
        confirmText="Contact Us"
        hideCancelButton={true}
        open={limitDialogOpen}
        content={
          <>
            <Typography mb={1} textAlign="center" variant="h5">
              You have reached the global limit of account names
            </Typography>
            <Typography>
              Please contact support if you need to add more than {inboxHelpers.MAX_ACCOUNTS_LIMIT} account names
            </Typography>
          </>
        }
      />
      <ConfirmationDialog
        onClose={closeDialog}
        onConfirm={handleDeleteConfirmation}
        confirmText="Yes"
        cancelText="No"
        open={open}
        content={
          <Typography textAlign="center" variant="h5">
            Are you sure to delete this name? This action cannot be undone.
          </Typography>
        }
      />
    </>
  );
}
