import Box from '@mui/material/Box';
import React, { ErrorInfo, ReactNode, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

export function ErrorBoundary({ children }: { children: ReactNode }) {
  const [hasError, setHasError] = useState(false);
  const [resetError, setResetError] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      if (hasError) {
        setHasError(false);
        setResetError((prev) => prev + 1);
      }
    };
  }, [hasError, pathname]);

  const logError = useCallback((error: Error, info: ErrorInfo) => {
    console.error(error, info);
    setHasError(true);
  }, []);

  return (
    <ReactErrorBoundary key={resetError} fallbackRender={() => <ErrorBoundaryFallback />} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
}
