import { Box, styled } from '@mui/material';
import { Button, FlexBox, Tooltip } from '@usgm/shared-ui';
import { useShipmentContext } from './steps/useShipmentContext';

const Root = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),
  height: theme.spacing(9.75),
  width: 'calc(100% - 6px)',
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.background.paper,
  boxSizing: 'border-box',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ActionButton = styled(Button)(() => ({
  width: '100%',
}));

export type ActionButtonsProps = {
  backLabel?: string;
  nextLabel?: string;
  onBackClick?: () => void;
  onNextClick?: () => void;
  canProceed: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  disableGoNext?: boolean;
};

function ActionButtons({
  backLabel = 'Back',
  nextLabel = 'Next',
  onBackClick,
  onNextClick,
  canProceed,
  errorMessage,
  isLoading = false,
  disableGoNext = false,
}: ActionButtonsProps) {
  const { goToNextStep, goToPreviousStep } = useShipmentContext();

  const handleBackClick = () => {
    onBackClick?.();
    goToPreviousStep();
  };

  const handleNextClick = () => {
    onNextClick?.();
    if (!disableGoNext) {
      goToNextStep();
    }
  };

  return (
    <Root>
      <ActionButton variant="outlined" onClick={handleBackClick}>
        {backLabel}
      </ActionButton>
      <Tooltip title={!canProceed ? errorMessage : ''}>
        <Box width="100%">
          <ActionButton isLoading={isLoading} disabled={!canProceed} variant="contained" onClick={handleNextClick}>
            {nextLabel}
          </ActionButton>
        </Box>
      </Tooltip>
    </Root>
  );
}

export default ActionButtons;
