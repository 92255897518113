import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { cellHelpers, dataTableConfig, ShipmentTrackingLink } from '@usgm/shared-ui';
import { COMMON_SHIPMENTS_COLUMNS } from './common';
import { BaseShipment } from '@usgm/inbox-api-types';

export const COMPLETED_SHIPMENTS_COLUMNS: GridColDef[] = [
  ...COMMON_SHIPMENTS_COLUMNS,
  {
    // @TODO: tracking is missing in the API response
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'trackingNumber',
    headerName: 'Tracking',
    renderCell: (params: GridRenderCellParams<BaseShipment>) => (
      <ShipmentTrackingLink serviceName={params.row.serviceName} trackingNumber={params.row.trackingNumber} />
    ),
  },
  {
    // @TODO: shipped on date is missing in the API response
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'shippedOn',
    headerName: 'Shipped On',
    valueGetter: (value: BaseShipment['shippedOn']) => cellHelpers.formatDateString(value),
  },
] as const;
