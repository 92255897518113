import { DateTime } from 'luxon';

import { PICKUP_SERVICE_TYPE } from '@inbox/constants';
import {
  DtoBankAccount,
  type DtoPlanDetails,
  type DtoShipment,
  type Rate,
  ShipmentCategory,
  type ShipmentRequestParams,
  ShipmentSignature,
  type ShippableItem,
  type UserWarehouseAddress,
} from '@usgm/inbox-api-types';
import { dateUtils, mailsShipmentUtils, pascalCase } from '@usgm/utils';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../../apiMessages/apiMessagesSlice';
import {
  useCreateMailsShippingRequestMutation,
  useUpdateMailsShippingRequestMutation,
} from '../../../../../shipments/api';
import { TAG_TYPES as MAILS_TAG_TYPES, mailsApi } from '../../../../api';
import {
  mailsToShipSelectors,
  selectBankAccount,
  selectDestinationAddress,
  selectDestinationAddressId,
  selectInsuranceAmount,
  selectInsureShipment,
  selectIsConsolidationCostPermitted,
  selectIsShipmentIncludesPackage,
  selectSelectedRate,
  selectShipmentType,
  selectShippingDetails,
  TShippingDetails,
} from '../mailsShipmentSlice';
import { useActiveSubscriptionPlanDetails } from './useActiveSubscriptionPlanDetails';

const constructShipmentRequestParams = ({
  destinationAddress,
  selectedRate,
  shippingDetails,
  insureShipment,
  insuranceAmount,
  isOpenAndConsolidatePermitted,
  mailsToShip,
  instructions,
  isShipmentIncludesPackage,
  shipmentSettings,
  destinationAddressId,
  shipmentCategory,
  bankAccount,
}: {
  insureShipment: boolean;
  insuranceAmount: number;
  selectedRate: Rate | null;
  destinationAddress: UserWarehouseAddress | DtoShipment['destination'] | null;
  shippingDetails: TShippingDetails;
  mailsToShip: ShippableItem[];
  instructions: string;
  isOpenAndConsolidatePermitted: boolean;
  isShipmentIncludesPackage: boolean;
  destinationAddressId: ShipmentRequestParams['destinationAddressId'];
  shipmentCategory: ShipmentCategory;
  shipmentSettings?: DtoPlanDetails['shipment'];
  bankAccount: DtoBankAccount | null;
}): ShipmentRequestParams | null => {
  if (shipmentCategory === ShipmentCategory.ReturnToSenderRequest) {
    return {
      items: mailsToShip.map((mail) => ({
        declarations: mail.shippingDeclarations || [],
        mailId: mail.id,
      })),
      shipmentCategoryType: shipmentCategory,
      shipmentDetail: {
        packingInstructions: 'Return to sender',
      },
      shipper: {
        name: '',
      },
    };
  }
  if (
    !selectedRate ||
    !destinationAddress ||
    (shipmentCategory === ShipmentCategory.ShipmentRequest && shippingDetails.requestedShipmentDate === null)
  ) {
    return null;
  }
  const requestedShipDate = shippingDetails.requestedShipmentDate
    ? dateUtils
        .adjustDateForShipmentCutoffOrNonBusiness(DateTime.fromISO(shippingDetails.requestedShipmentDate))
        .toISO()
    : null;

  const baseRate = selectedRate.detailedCharges.find((charge) => charge.type === 'base');

  const data: ShipmentRequestParams = {
    destinationAddress: {
      addressLine: destinationAddress.addressLine,
      name: destinationAddress.name,
      phoneNumber: destinationAddress.phoneNumber,
      city: destinationAddress.city,
      country: destinationAddress.country,
      postalCode: destinationAddress.postalCode,
      state: destinationAddress.state,
    },
    destinationAddressId,
    insuredAmount: insureShipment ? insuranceAmount : 0,
    items: mailsToShip.map((mail) => ({
      declarations: mail.shippingDeclarations || [],
      mailId: mail.id,
    })),
    shipmentCategoryType:
      selectedRate.serviceType === PICKUP_SERVICE_TYPE ? ShipmentCategory.CustomerPickupRequest : shipmentCategory,
    shipmentDetail: {
      consolidationCost: isOpenAndConsolidatePermitted
        ? mailsShipmentUtils.getConsolidationCost({
            isShipmentIncludesPackage,
            settings: shipmentSettings,
          })
        : 0,
      isNewItemsIncluded: shippingDetails.includeNewItems,
      isOpenAndConsolidatePermitted: shippingDetails.isConsolidationCostPermitted,
      isExpedited: shippingDetails.isExpediteShipping,
      packingInstructions: instructions,
      requestedShipDate: requestedShipDate || '',
      signatureId: shippingDetails?.signatureRequired ? ShipmentSignature.Required : ShipmentSignature.NotRequired,
    },
    shipper: {
      name: selectedRate?.serviceName,
      serviceType: selectedRate?.serviceType,
      slug: selectedRate?.shipperAccount.slug,
      isShipToday: selectedRate?.shipToday,
      isTrackable: selectedRate?.trackable,
      eta: selectedRate?.deliveryDate || null,
      retailRate: selectedRate?.totalCharge.amount,
      ourRate: selectedRate?.totalCharge.amount,
      baseRate: baseRate?.charge.amount || 0,
    },
  };

  if (destinationAddress.addressLine2 && data.destinationAddress) {
    data.destinationAddress.addressLine2 = destinationAddress.addressLine2;
  }
  if (destinationAddress.addressLine3 && data.destinationAddress) {
    data.destinationAddress.addressLine3 = destinationAddress.addressLine3;
  }
  if (shipmentCategory === ShipmentCategory.CheckDepositRequest && bankAccount) {
    data.bankDetail = {
      name: bankAccount.bankName,
      state: bankAccount.bankState,
      accountNumber: bankAccount.accountNumber,
    };
  }
  return data;
};

export function useUpsertShippingRequest(instructions: string) {
  const dispatch = useAppDispatch();

  const destinationAddress = useAppSelector(selectDestinationAddress);
  const selectedRate = useAppSelector(selectSelectedRate);
  const insureShipment = useAppSelector(selectInsureShipment);
  const insuranceAmount = useAppSelector(selectInsuranceAmount);
  const mailsToShip = useAppSelector(mailsToShipSelectors.selectAll);
  const isOpenAndConsolidatePermitted = useAppSelector(selectIsConsolidationCostPermitted);
  const isShipmentIncludesPackage = useAppSelector(selectIsShipmentIncludesPackage);
  const destinationAddressId = useAppSelector(selectDestinationAddressId);
  const shipmentCategory = useAppSelector(selectShipmentType);
  const bankAccount = useAppSelector(selectBankAccount);

  const { activeSubscriptionPlanQuery } = useActiveSubscriptionPlanDetails();

  const shippingDetails = useAppSelector(selectShippingDetails);

  const [createMailsShippingRequest, createQuery] = useCreateMailsShippingRequestMutation();
  const [updateMailsShippingRequest, updateQuery] = useUpdateMailsShippingRequestMutation();

  const upsertMailsShippingRequest = async ({ showSuccessMessage }: { showSuccessMessage?: boolean }) => {
    const params = constructShipmentRequestParams({
      destinationAddress,
      selectedRate,
      shippingDetails,
      insureShipment,
      insuranceAmount,
      isOpenAndConsolidatePermitted,
      mailsToShip,
      instructions,
      isShipmentIncludesPackage,
      shipmentSettings: activeSubscriptionPlanQuery.data?.shipment,
      destinationAddressId,
      shipmentCategory: shipmentCategory || ShipmentCategory.ShipmentRequest,
      bankAccount,
    });
    if (!params) {
      return;
    }

    let request;

    if (shippingDetails.shipmentId) {
      request = updateMailsShippingRequest({
        data: params,
        shipmentId: shippingDetails.shipmentId,
      });
    } else {
      request = createMailsShippingRequest(params);
    }

    const response = await request;

    const hasError = response && 'error' in response;
    if (hasError) {
      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: 'error',
          text: `Unable to ${shippingDetails.shipmentId ? 'update' : 'submit'} the request. Please try again.`,
        }),
      );
    } else {
      dispatch(
        mailsApi.util.invalidateTags([
          ...mailsToShip.map((mail) => ({ type: MAILS_TAG_TYPES.MAIL_ITEM, id: mail.id })),
          MAILS_TAG_TYPES.MAILS_LIST,
        ]),
      );
      if (showSuccessMessage) {
        dispatch(
          apiMessagesSlice.actions.createMessage({
            severity: 'success',
            text: `${pascalCase(shipmentCategory || '')} submitted successfully`,
          }),
        );
      }
    }

    return request;
  };

  return {
    upsertMailsShippingRequest,
    upsertQuery: shippingDetails.shipmentId ? updateQuery : createQuery,
  };
}
