import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { WarehouseStatus } from '@usgm/inbox-api-types';
import { FlexBox, RoundedBadge, SectionTitle, Tooltip } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { ReactNode } from 'react';
import LocationIcon from '../../../../assets/location.svg?react';
import { PartialBy } from '../../../../helpers/typeHelpers';
import { TMeasuredWarehouse } from '../../addressPickerSlice';
import RecommendedBadges from './RecommendedBadges';

const CardRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export interface WarehouseCardProps extends PartialBy<TMeasuredWarehouse, 'distance'> {
  recommended?: boolean;
  variant?: 'card' | 'list';
  onSelect?: (id: TMeasuredWarehouse['id']) => void;
}

const getSelectButtonTooltipText = (
  {
    hasAvailableBoxes,
    isApproved,
  }: {
    hasAvailableBoxes: boolean,
    isApproved: boolean,
  }): string | null => {
  if (!isApproved) {
    return 'We\'re in the process of setting up the warehouse. Please check back soon for updates.';
  }
  if (!hasAvailableBoxes) {
    return 'This warehouse has no available box numbers at this moment';
  }
  return null;
}

const SelectButtonContainer = (
  {
    hasAvailableBoxes,
    isApproved,
    children,
  }: {
    hasAvailableBoxes: boolean,
    isApproved: boolean,
    children: ReactNode,
  }) => {
  const tooltipText = getSelectButtonTooltipText({ hasAvailableBoxes, isApproved })
  return (
    <Tooltip title={tooltipText}>
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
};

export default function WarehouseInnerCard(props: WarehouseCardProps) {
  const theme = useTheme();
  const { recommended, address, variant = 'list', distance, onSelect, occupiedBoxNumbersCount, capacity, warehouseStatus } = props;
  const iconBg = variant === 'card' ? (recommended ? '#ECF4FF' : theme.palette.common.white) : '#ECF4FF';
  const iconColor = recommended ? theme.palette.primary.main : '#B4CFFE';
  const onClickHandler = () => {
    onSelect?.(props.id);
  };
  const hasAvailableBoxes = (occupiedBoxNumbersCount || 0) < capacity;
  const isApproved = warehouseStatus?.status === WarehouseStatus.Approved;
  const selectDisabled = !hasAvailableBoxes || !isApproved;
  return (
    <CardRoot>
      <FlexBox mb={1.5} width="100%" alignItems="flex-start">
        <RoundedBadge color={iconColor} bg={iconBg} size={theme.spacing(8.5)}>
          <LocationIcon />
        </RoundedBadge>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 3, paddingRight: 5.25, flex: 1 }}>
          <SectionTitle sx={{ textAlign: 'left', marginBottom: 0 }}>{address.name}</SectionTitle>
          <Typography sx={{ color: theme.customColors.dark[400] }}>
            {inboxHelpers.stringifyAddress({ addr: address })}
          </Typography>
        </Box>
        {variant === 'list' && onSelect && (
          <SelectButtonContainer hasAvailableBoxes={hasAvailableBoxes} isApproved={isApproved}>
            <Button
              onClick={onClickHandler}
              size="small"
              variant={recommended ? 'contained' : 'outlined'}
              disabled={selectDisabled}
            >
              Select
            </Button>
          </SelectButtonContainer>
        )}
      </FlexBox>

      {recommended && variant !== 'card' && <RecommendedBadges warehouseId={props.id} />}
      {variant === 'card' && (
        <FlexBox alignItems="end">
          {recommended ? (
            <RecommendedBadges warehouseId={props.id} />
          ) : (
            distance && <Typography color={theme.palette.text.secondary}>{Math.round(distance)} miles away</Typography>
          )}

          {onSelect && (
            <SelectButtonContainer hasAvailableBoxes={hasAvailableBoxes} isApproved={isApproved}>
              <Box>
                <Button
                  onClick={onClickHandler}
                  size="medium"
                  disabled={selectDisabled}
                  variant={recommended ? 'contained' : 'outlined'}
                >
                  Select
                </Button>
              </Box>
            </SelectButtonContainer>
          )}
        </FlexBox>
      )}
    </CardRoot>
  );
}
