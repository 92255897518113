import {
  BaseShipment,
  DeepSnakeCaseKeys,
  DtoShipInfo,
  DtoShipment,
  ResponseInfo,
  ShipmentRequestParams,
  type DtoShipmentsList,
  type EntityFilter,
  type PaginationParams,
  type TShipmentFilters,
} from '@usgm/inbox-api-types';
import { inboxHelpers, snakeCaseKeys } from '@usgm/utils';
import { mailsApi as baseMailsApi } from '../../../../api/mailsApi';
import { SHIPMENT_TABS, ShipmentPathParams } from './paths';

export const TAG_TYPES = {
  SHIPMENT: 'Shipment',
  SHIPMENTS_LIST: 'ShipmentsList',
} as const;

const prepareShipmentRequestData = (data: ShipmentRequestParams) => {
  const snakeCasedData: DeepSnakeCaseKeys<ShipmentRequestParams> = snakeCaseKeys(data);
  const { signature_id, ...shipment_detail } = snakeCasedData.shipment_detail;
  return {
    ...snakeCasedData,
    shipment_detail: {
      ...shipment_detail,
      signatureId: signature_id,
    },
  };
};

export const shipmentsApi = baseMailsApi
  .enhanceEndpoints({
    addTagTypes: [TAG_TYPES.SHIPMENT, TAG_TYPES.SHIPMENTS_LIST],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getShipmentById: builder.query<DtoShipment, { id: string }>({
        query: ({ id }) => ({
          url: `/shipments/v2/${id}`,
          method: 'get',
        }),
        providesTags: (result) => (result ? [{ type: TAG_TYPES.SHIPMENT, id: result.uuid }] : []),
      }),
      getShipmentsList: builder.query<
        DtoShipmentsList,
        PaginationParams & Omit<ShipmentPathParams, 'itemId'> & { filter?: EntityFilter<TShipmentFilters, string[]> }
      >({
        query: ({ tab = 'pending', limit = inboxHelpers.DEFAULT_PAGINATION_LIMIT, skip = 0, filter }) => {
          const filtersToApply = filter ? { ...filter } : {};
          const currentTabStatus = SHIPMENT_TABS.find((item) => item.value === tab)?.filterKey;

          if (currentTabStatus) {
            filtersToApply['status'] = [currentTabStatus];
          }

          const filterQueryString = inboxHelpers.constructListFilters(filtersToApply);

          return {
            url: `/shipments/v2${filterQueryString ? '?' + filterQueryString : ''}`,
            method: 'get',
            params: {
              skip,
              limit,
            },
          };
        },
        providesTags: (result) =>
          result && result.list
            ? [
                ...result.list.map((item) => ({ type: TAG_TYPES.SHIPMENT, id: item.uuid })),
                { type: TAG_TYPES.SHIPMENTS_LIST },
              ]
            : [TAG_TYPES.SHIPMENTS_LIST],
      }),
      cancelShipment: builder.mutation<ResponseInfo, { id: DtoShipment['uuid'] }>({
        query: ({ id }) => ({
          // @TODO: we shouldn't send this query param, it should be handled by the backend
          url: `/shipment/${id}/cancel?is_user_submitted=true`,
          method: 'put',
        }),
        invalidatesTags: (_, error, { id }) => (!error ? [{ type: TAG_TYPES.SHIPMENT, id }] : []),
      }),
      getShipInfo: builder.query<DtoShipInfo, void | null>({
        query: () => ({
          url: '/ship/info',
          method: 'get',
        }),
      }),
      createMailsShippingRequest: builder.mutation<
        ResponseInfo & { shipmentId: BaseShipment['uuid'] },
        ShipmentRequestParams
      >({
        query: (data) => ({
          url: '/shipment',
          method: 'post',
          data: prepareShipmentRequestData(data),
        }),
        invalidatesTags: (_, error) => (!error ? [{ type: TAG_TYPES.SHIPMENTS_LIST }] : []),
      }),
      updateMailsShippingRequest: builder.mutation<
        ResponseInfo & { shipmentId: BaseShipment['uuid'] },
        { data: ShipmentRequestParams } & { shipmentId: BaseShipment['uuid'] }
      >({
        query: ({ shipmentId, data }) => ({
          // @TODO: userSubmitted should be removed from the URL
          url: `/shipment/${shipmentId}?userSubmitted=true`,
          method: 'put',
          data: prepareShipmentRequestData(data),
        }),
        invalidatesTags: (_, error, { shipmentId }) => (!error ? [{ type: TAG_TYPES.SHIPMENT, id: shipmentId }] : []),
      }),
    }),
  });

export const {
  useGetShipmentsListQuery,
  useGetShipmentByIdQuery,
  useCancelShipmentMutation,
  useGetShipInfoQuery,
  useCreateMailsShippingRequestMutation,
  useUpdateMailsShippingRequestMutation,
} = shipmentsApi;
