import { Box, IconButton, Typography, styled } from '@mui/material';
import { AddButton, MultiSelectionList, Tooltip } from '@usgm/shared-ui';
import { useCallback, useMemo } from 'react';

import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

import { useAuth } from '../../../../../auth/hooks/useAuth';
import { useGenericFilters } from '../../../../components/filters/useGenericFilters';

import { MAILS_FILTERS, TMailFilters } from '@usgm/inbox-api-types';
import { useMailFolders } from '../../hooks/useMailFolders';
import { TFolder } from '../../mailFolders/folderSlice';

const FilterTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 40,
  marginBottom: theme.spacing(2),
}));

type MailFiltersProps = {
  onAddFolderClick: () => void;
};

function MailsFilters({ onAddFolderClick }: MailFiltersProps) {
  const { isRegularTeamMember } = useAuth();
  const { allFolders, folderIdExtractor, folderLabelExtractor } = useMailFolders();

  const { getFilterByKey, updateFilterByKey, clearFilterByKey } = useGenericFilters<TMailFilters>(MAILS_FILTERS);

  const handleFolderChange = useCallback(
    (selectedItems: Array<string | number>) => {
      updateFilterByKey(
        'folder',
        selectedItems.map((id) => id.toString()),
      );
    },
    [updateFilterByKey],
  );

  const handleReadStatusChange = useCallback(
    (selectedItems: Array<string | number>) => {
      updateFilterByKey(
        'isRead',
        selectedItems.map((id) => id.toString()),
      );
    },
    [updateFilterByKey],
  );

  const selectedFolders = getFilterByKey('folder');
  const selectedReadStatusFilters = getFilterByKey('isRead');

  const folders: TFolder[] = allFolders?.length
    ? [...(!isRegularTeamMember ? [{ label: 'Unassigned', id: 'unassigned', uuid: 'unassigned' }] : []), ...allFolders]
    : [];

  const readStatusFilterItems = useMemo(() => [{ label: 'New (Unread)', id: 'false', uuid: 'unread' }], []);

  return (
    <>
      <Box>
        <FilterTitle variant="h5">
          Filter by read status
          {selectedReadStatusFilters.length > 0 && (
            <Tooltip title="Clear read status filter">
              <IconButton
                color="secondary"
                onClick={() => clearFilterByKey('isRead')}
                aria-label="clear read status filter"
              >
                <FilterAltOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </FilterTitle>
        <Box mb={2}>
          <MultiSelectionList
            selected={selectedReadStatusFilters}
            onChange={handleReadStatusChange}
            items={readStatusFilterItems}
            keyExtractor={folderIdExtractor}
            labelExtractor={folderLabelExtractor}
            enableSearch={false}
            multiple={false}
          />
        </Box>
      </Box>
      <Box>
        <FilterTitle variant="h5">
          Filter by folder
          {selectedFolders.length > 0 && (
            <Tooltip title="Clear folder filter">
              <IconButton color="secondary" onClick={() => clearFilterByKey('folder')} aria-label="clear folder filter">
                <FilterAltOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </FilterTitle>
        <Box mb={2}>
          <MultiSelectionList
            selected={selectedFolders}
            onChange={handleFolderChange}
            items={folders}
            keyExtractor={folderIdExtractor}
            labelExtractor={folderLabelExtractor}
          />
          {!isRegularTeamMember && (
            <Box mt={1}>
              <AddButton onClick={onAddFolderClick} label="Add Folder" />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default MailsFilters;
