import { Box, Theme, styled, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import FlexBox from '../FlexBox';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoTitle from '../InfoTitle';

const PasswordStrengthBar = styled(Box)<{ color?: string }>(({ theme, color }) => ({
  height: 4,
  borderRadius: 4,
  backgroundColor: color || theme.palette.divider,
  position: 'relative',
  overflow: 'hidden',
}));

export type PasswordStrengthIndicatorProps = {
  value?: string;
  passwordRules: Array<{
    rule: RegExp;
    optional?: boolean;
    error: string;
  }>;
  minLength: number;
  tooltipText: string;
  labels: Array<{ label: string; getColor: (theme: Theme) => string }>;
};

export function PasswordStrengthIndicator({
  value,
  passwordRules,
  minLength,
  labels,
  tooltipText = 'Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number.',
}: PasswordStrengthIndicatorProps) {
  const theme = useTheme();
  const strength = useMemo(() => {
    let calculatedStrength = 0;
    if (!value) {
      return calculatedStrength;
    }
    passwordRules.forEach(({ rule }) => {
      if (rule.test(value)) {
        calculatedStrength += 1;
      }
    });
    if (value.length >= minLength) {
      calculatedStrength += 1;
    }
    return calculatedStrength;
  }, [value, passwordRules, minLength]);

  const strengthLabel = strength > 0 ? labels[strength - 1].label : '';
  const strengthColor = strength > 0 ? labels[strength - 1].getColor(theme) : '';

  return (
    <>
      <FlexBox mt={2} gap={2}>
        {labels.map((__, index) => (
          <PasswordStrengthBar
            color={index < strength ? strengthColor : undefined}
            key={index}
            width={`${100 / labels.length}%`}
          />
        ))}
      </FlexBox>

      <FlexBox height={32} justifyContent={'flex-end'}>
        <InfoTitle
          fontWeight="600"
          color={theme.palette.text.secondary}
          title={strengthLabel}
          description={tooltipText}
          icon={<HelpOutlineOutlinedIcon />}
          underline={!!strengthLabel}
        />
      </FlexBox>
    </>
  );
}

export default React.memo(PasswordStrengthIndicator);
