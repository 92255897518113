import { useMemo } from 'react';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { Control, Controller, useFieldArray, useForm, UseFormRegister } from 'react-hook-form';

import { Box, InputBaseComponentProps, styled } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { BaseMail, ShippingDeclaration } from '@usgm/inbox-api-types';
import { AddButton, Button, FlexBox, RemoveButton, TextInput, Toast, Tooltip } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import { usePostMailDeclarationsMutation } from '../../api';

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const DECLARATION_SCHEMA = z.object({
  description: z.string().min(1, 'Description is required.').describe('Description'),
  quantity: z.coerce.number().int().min(1, 'Please enter a valid quantity.').describe('Quantity'),
  itemValue: z.coerce.number().int().min(1, 'Please enter a valid item value.').describe('Item Value'),
  isInsured: z.boolean().optional().default(false),
});

type TDeclaration = z.infer<typeof DECLARATION_SCHEMA>;

const declarationsFormSchema = z.object({
  declarations: z.array(DECLARATION_SCHEMA),
});

type TDeclarationsFormSchemaType = z.infer<typeof declarationsFormSchema>;

export const DEFAULT_DECLARATION: TDeclaration = { description: '', isInsured: false, itemValue: 1, quantity: 1 };

export type ShippingDeclarationsProps = {
  declarations: ShippingDeclaration[] | null;
  mailId: BaseMail['id'];
};

export function DeclarationsRow({
  control,
  register,
  isSubmitting = false,
  fieldsPath,
  index,
  remove,
  errors,
  showRemoveButton,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  isSubmitting?: boolean;
  fieldsPath: string;
  index: number;
  remove: (index: number) => void;
  showRemoveButton: boolean;
  errors: {
    declarations?: string;
    itemValue?: string;
    quantity?: string;
  };
}) {
  const handleNumberInputChange = (onChange: (val: number) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/[^0-9]/)) {
      return e.preventDefault();
    }

    onChange(+e.target.value);
  };
  const handleNumberInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === '+' || e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };
  const numberInputProps: InputBaseComponentProps = { min: 1, step: 1, inputMode: 'numeric' };
  return (
    <FlexBox alignItems="flex-start">
      <FlexBox gap={1.5} maxWidth="calc(100% - 32px)" sx={{ zIndex: 0 }}>
        <StyledTextInput
          {...register(`${fieldsPath}.description`)}
          disabled={isSubmitting}
          error={!!errors.declarations}
          helperText={errors.declarations}
          InputLabelProps={{ shrink: true }}
          placeholder="Eg. Laptop"
          sx={{ minWidth: '40%' }}
          fullWidth
          required
          label="Item Description"
        />
        <Controller
          control={control}
          name={`${fieldsPath}.itemValue`}
          render={({ field: { onChange, ...field } }) => (
            <StyledTextInput
              {...field}
              disabled={isSubmitting}
              error={!!errors.itemValue}
              //helperText={errors.itemValue}
              InputLabelProps={{ shrink: true }}
              inputProps={numberInputProps}
              type="number"
              fullWidth
              required
              label="Price/Item ($)"
              InputProps={{
                startAdornment: errors.itemValue ? (
                  <Tooltip title={errors.itemValue}>
                    <ErrorOutlineOutlinedIcon color="error" />
                  </Tooltip>
                ) : undefined,
              }}
              onKeyDown={handleNumberInputKeyDown}
              onChange={handleNumberInputChange(onChange)}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldsPath}.quantity`}
          render={({ field: { onChange, ...field } }) => (
            <StyledTextInput
              {...field}
              disabled={isSubmitting}
              error={!!errors.quantity}
              //helperText={errors.quantity}
              inputProps={numberInputProps}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: errors.quantity ? (
                  <Tooltip title={errors.quantity}>
                    <ErrorOutlineOutlinedIcon color="error" />
                  </Tooltip>
                ) : undefined,
              }}
              type="number"
              fullWidth
              required
              label="No. of Items"
              onChange={handleNumberInputChange(onChange)}
              onKeyDown={handleNumberInputKeyDown}
            />
          )}
        />
      </FlexBox>

      {showRemoveButton && (
        <Box marginTop={1.75}>
          <RemoveButton onClick={() => remove(index)} />
        </Box>
      )}
    </FlexBox>
  );
}

function ShippingDeclarationsManager({ declarations, mailId }: ShippingDeclarationsProps) {
  const [postDeclarationsMutation, { isError, isLoading, isSuccess }] = usePostMailDeclarationsMutation();

  const defaultValues = useMemo(() => ({ declarations: declarations || [DEFAULT_DECLARATION] }), [declarations]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset: resetForm,
  } = useForm<TDeclarationsFormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(declarationsFormSchema),
    defaultValues,
  });

  const isSubmitting = isLoading;
  const { append, fields, remove } = useFieldArray({ control, name: 'declarations' });
  const showSaveButton = isValid && isDirty && fields.length > 0;
  const onFormSubmit = async (data: TDeclarationsFormSchemaType) => {
    const response = await postDeclarationsMutation({
      declarations: data.declarations,
      mailId,
    });

    if (!('error' in response)) {
      resetForm(data);
    }
  };

  return (
    <Box noValidate onSubmit={handleSubmit(onFormSubmit)} component="form">
      {fields.map((_, index) => {
        return (
          <DeclarationsRow
            errors={{
              declarations: errors.declarations?.[index]?.description?.message,
              itemValue: errors.declarations?.[index]?.itemValue?.message,
              quantity: errors.declarations?.[index]?.quantity?.message,
            }}
            control={control}
            fieldsPath={`declarations.${index}`}
            index={index}
            key={index}
            remove={remove}
            register={register}
            showRemoveButton={fields.length > 1}
          />
        );
      })}
      <FlexBox minHeight={48} mt={2}>
        <AddButton
          label={fields.length > 0 ? 'Add a declaration' : 'Add another declaration'}
          onClick={() => append(DEFAULT_DECLARATION)}
        />
        {showSaveButton && (
          <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting} variant="contained" size="small">
            Save
          </Button>
        )}
      </FlexBox>
      {(isError || isSuccess) && (
        <Toast
          severity={isError ? 'error' : 'success'}
          title={isError ? inboxHelpers.GENERIC_ERROR_MESSAGE : 'Successfully submitted mail declarations.'}
        />
      )}
    </Box>
  );
}

export default ShippingDeclarationsManager;
