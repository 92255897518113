import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DtoUpdateWarehouse, TokenData, type DtoWarehouse, type Location } from '@usgm/inbox-api-types';
import { RootState } from '../../store';

import { haversineDistance, inboxHelpers } from '@usgm/utils';

import { jwtDecode } from 'jwt-decode';
import { addressPickerApi } from './api';
import { MIN_DISTANCE } from './config';
import { warehousesConfig } from '@inbox/constants';

const FEATURE_NAME = 'ADDRESS_PICKER';

type TWarehouseEntity = DtoWarehouse & { active?: boolean };

export type TMeasuredWarehouse = TWarehouseEntity & { distance: number };

type TState = EntityState<TWarehouseEntity, number> & {
  selectedLocation: null | Location;
  measuredWarehouses: Array<TMeasuredWarehouse>;
  offset: number;
  place: google.maps.places.AutocompletePrediction | null;
  pickedWarehouse: null | DtoWarehouse['id'];
  activeWarehouseId: null | DtoWarehouse['id'];
};

const LIMIT = 3;

const createNotRecommendedFilter = (state: TState) => (id: number) => !state.entities[id].recommended;

const sortFn = (a: DtoWarehouse, b: DtoWarehouse) => a.address.state.localeCompare(b.address.state);

const warehousesAdapter = createEntityAdapter<TWarehouseEntity & { active?: boolean }>();

const initialState: TState = warehousesAdapter.getInitialState({
  selectedLocation: null,
  measuredWarehouses: [],
  offset: 0,
  place: null,
  pickedWarehouse: inboxHelpers.getStorageManager().getItem('selectedWarehouse') || null,
  activeWarehouseId: null,
});

export const addressPickerSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setSelectedLocation: (state, action: PayloadAction<TState['selectedLocation']>) => {
      state.selectedLocation = action.payload;
      state.offset = 0;
      if (action.payload) {
        state.measuredWarehouses = state.ids
          .map((id) => {
            const warehouse = state.entities[id];
            return {
              ...warehouse,
              distance: haversineDistance(action.payload as Location, warehouse.location, 'mile'),
            };
          })
          .sort((a, b) => a.distance - b.distance);
      } else {
        state.measuredWarehouses = [];
      }
    },
    setActiveWarehouseId: (state, action: PayloadAction<TState['activeWarehouseId']>) => {
      const warehouseId = action.payload || state.activeWarehouseId;

      if (state.activeWarehouseId) {
        warehousesAdapter.updateOne(state, { id: state.activeWarehouseId, changes: { active: false } });
      }

      if (warehouseId) {
        warehousesAdapter.updateOne(state, { id: warehouseId, changes: { active: !!action.payload } });
      }

      state.activeWarehouseId = action.payload;
    },
    setPlace: (state, action: PayloadAction<TState['place']>) => {
      state.place = action.payload;
    },
    loadMoreWarehouses: (state, { payload: { skipRecommended } }: PayloadAction<{ skipRecommended: boolean }>) => {
      if (!state.selectedLocation) {
        state.offset += LIMIT;
      } else {
        state.offset = filterByDistanceRange(state.measuredWarehouses, state.offset, skipRecommended).length;
      }
    },
    setWarehouse: (state, action: PayloadAction<DtoWarehouse['id']>) => {
      state.pickedWarehouse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(addressPickerApi.endpoints.getWarehouses.matchFulfilled), (state, action) => {
      const recommended: DtoWarehouse[] = [];
      const notRecommended: DtoWarehouse[] = [];

      action.payload.results.forEach((warehouse) => {
        if (warehousesConfig.RECOMMENDED_WAREHOUSES.includes(warehouse.id)) {
          recommended.push({ ...warehouse, recommended: true });
        } else {
          notRecommended.push({ ...warehouse, recommended: false });
        }
      });

      state.measuredWarehouses = [];
      state.offset = 0;
      warehousesAdapter.setAll(state, recommended.sort().concat(notRecommended.sort(sortFn)));
    });
    builder.addMatcher(
      isAnyOf(addressPickerApi.endpoints.updateWarehouse.matchFulfilled),
      (_, action: PayloadAction<DtoUpdateWarehouse>) => {
        const auth = inboxHelpers.getStorageManager().getItem('authData');
        if (!auth || !action.payload.accessToken) {
          return;
        }
        const tokenData = jwtDecode<TokenData>(action.payload.accessToken);
        const data = {
          ...auth,
          data: tokenData,
          boxNumber: action.payload.warehouseBoxNumber,
          token: action.payload.accessToken,
          accountStatus: auth.accountStatus,
        };
        inboxHelpers.getStorageManager().setItem('authData', data);
        window.location.reload();
      },
    );
  },
});

export const setWarehouse = createAsyncThunk<
  void,
  { id: DtoWarehouse['id']; callback?: () => void },
  { state: RootState }
>(`${FEATURE_NAME}/SET_WAREHOUSE`, async ({ id, callback }, { dispatch, getState }) => {
  const warehouse = warehousesSelectors.selectById(getState(), id);
  if (warehouse) {
    inboxHelpers.getStorageManager().setItem('selectedWarehouse', warehouse.id);
    dispatch(addressPickerSlice.actions.setWarehouse(warehouse.id));
    callback?.();
  }
});

export const { setSelectedLocation, loadMoreWarehouses, setPlace } = addressPickerSlice.actions;

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];
export const warehousesSelectors = warehousesAdapter.getSelectors(selectState);

export const selectSelectedLocation = (state: RootState) => selectState(state).selectedLocation;
export const selectPlace = (state: RootState) => selectState(state).place;

const filterByDistanceRange = (warehouses: TMeasuredWarehouse[], offset: number, skipRecommended: boolean) => {
  const warehouseList = skipRecommended ? warehouses.filter((w) => !w.recommended) : warehouses;
  const nearestWarehouse = warehouseList[offset];
  if (!nearestWarehouse) return warehouseList;

  const upperBound = Math.ceil(nearestWarehouse.distance / MIN_DISTANCE);
  return warehouseList.filter((warehouse) => warehouse.distance <= upperBound * MIN_DISTANCE);
};

export const selectFilteredWarehouses = createSelector(
  selectState,
  (_: RootState, params: { paginate: boolean; skipRecommended: boolean }) => params,
  (state, { paginate, skipRecommended }) => {
    if (state.measuredWarehouses.length > 0) {
      return filterByDistanceRange(state.measuredWarehouses, state.offset, skipRecommended);
    }
    if (!paginate) {
      return state.ids.map((id) => state.entities[id]);
    }

    return state.ids
      .filter(createNotRecommendedFilter(state))
      .slice(0, state.offset)
      .map((id) => state.entities[id]);
  },
);

export const selectFilteredWarehousesIds = createSelector(
  selectState,
  (_: RootState, params: { paginate: boolean; skipRecommended: boolean }) => params,
  (state, { paginate, skipRecommended }) => {
    if (state.measuredWarehouses.length > 0) {
      return filterByDistanceRange(state.measuredWarehouses, state.offset, skipRecommended).map((w) => w.id);
    }
    if (!paginate) {
      return state.ids;
    }
    return state.ids.filter(createNotRecommendedFilter(state)).slice(0, state.offset);
  },
);

export const selectMeasuredWarehouses = (state: RootState) => selectState(state).measuredWarehouses;

export const selectOffset = (state: RootState) => selectState(state).offset;

export const selectCanLoadMore = (
  rootState: RootState,
  { paginate, skipRecommended }: { paginate: boolean; skipRecommended: boolean },
) => {
  const state = selectState(rootState);
  const { measuredWarehouses, offset, selectedLocation, ids } = state;

  if (!selectedLocation && paginate) {
    return offset < ids.filter(createNotRecommendedFilter(state)).length;
  }
  const warehouseList = skipRecommended ? measuredWarehouses.filter((w) => !w.recommended) : measuredWarehouses;
  const newOffset = filterByDistanceRange(measuredWarehouses, offset, skipRecommended).length;

  return newOffset < warehouseList.length;
};

export const selectPickedWarehouse = (state: RootState) => selectState(state).pickedWarehouse;

export const selectPickedWarehouseData = (state: RootState) => {
  const { entities, pickedWarehouse } = selectState(state);
  return pickedWarehouse ? entities[pickedWarehouse] : null;
};

export const selectActiveWarehouseId = (state: RootState) => selectState(state).activeWarehouseId;
