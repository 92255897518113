import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { loadMoreWarehouses, selectCanLoadMore } from '../addressPickerSlice';
import { useMobileMode } from '@usgm/shared-ui';

export const usePaginateWarehouses = () => {
  const isMobile = useMobileMode();
  const dispatch = useAppDispatch();
  const canLoadMore = useAppSelector((state) =>
    selectCanLoadMore(state, {
      skipRecommended: isMobile,
      paginate: isMobile,
    }),
  );

  const loadMore = useCallback(() => {
    dispatch(loadMoreWarehouses({ skipRecommended: isMobile }));
  }, [dispatch, isMobile]);

  return { canLoadMore, loadMore };
};
