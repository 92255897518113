import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { AutocompleteRenderInputParams, Box, IconButton, styled, TextField } from '@mui/material';
import { TextInput, useMobileMode } from '@usgm/shared-ui';

import GlobIcon from '../../../../assets/globe.svg?react';
import MyLocationButton from './MyLocationButton';

type AutocompleteInputProps = AutocompleteRenderInputParams & {
  placeholder?: string;
  onClearInput?: () => void;
  filled?: boolean;
};

const SearchBox = styled(TextField, { shouldForwardProp: (prop) => prop !== 'mobile' })<{ mobile: boolean }>(
  ({ theme, mobile }) => ({
    '& .MuiInputBase-root': {
      padding: '1.2rem 1.5rem !important',
      borderRadius: '1000px',
      border: '1px solid #B4CFFE',
      background: theme.palette.background.paper,
      boxShadow: '0px 0px 0px 4px #DBE7FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      ...(mobile && {
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[1],
        border: 'none',
        padding: '1.5rem 1rem !important',
        '& svg': {
          width: 32,
          height: 32,
        },
      }),
    },
  }),
);

const StartAdornment = styled('div', { shouldForwardProp: (prop) => prop !== 'showBorder' })<{ showBorder?: boolean }>(
  ({ theme, showBorder = true }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '& span .MuiSvgIcon-root': {
      color: theme.customColors.dark[400],
    },
    '& span': {
      display: 'flex',
      paddingLeft: showBorder ? theme.spacing(1) : 0,
      marginLeft: theme.spacing(1),
      borderLeft: showBorder ? `1px solid ${theme.palette.divider}` : 'none',
    },
  }),
);

const ClearSearch = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  marginRight: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.divider}`,
  height: '1.5rem',
  '& .MuiButtonBase-root': {
    padding: theme.spacing(0, 0.25),
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    borderRight: 'none',
  },
}));

const StyledFormInput = styled(TextInput)(({ theme }) => ({
  '& .MuiInputBase-root': {
    paddingRight: `${theme.spacing(1)} !important`,
  },
}));

export default function AutocompleteInput(
  props: AutocompleteInputProps & { variant?: 'form' | 'search'; label?: string; required?: boolean },
) {
  const { onClearInput, filled, label, variant = 'search', required, ...searchBoxProps } = props;

  const isMobile = useMobileMode();

  if (variant === 'form') {
    return (
      <StyledFormInput
        {...searchBoxProps}
        InputLabelProps={{ shrink: true }}
        label={label}
        required={required}
        placeholder={props.placeholder}
        InputProps={{
          ...searchBoxProps.InputProps,
          autoComplete: 'off',
          endAdornment: filled && onClearInput && (
            <IconButton onClick={onClearInput}>
              <CloseIcon />
            </IconButton>
          ),
          startAdornment: (
            <StartAdornment showBorder={false}>
              <span>
                <SearchIcon />
              </span>
            </StartAdornment>
          ),
        }}
        fullWidth
      />
    );
  }

  return (
    <SearchBox
      {...searchBoxProps}
      hiddenLabel
      mobile={isMobile}
      InputProps={{
        ...searchBoxProps.InputProps,
        endAdornment: (
          <>
            {filled && onClearInput && (
              <ClearSearch>
                <IconButton onClick={onClearInput}>
                  <CloseIcon />
                </IconButton>
              </ClearSearch>
            )}
            {!isMobile && <MyLocationButton />}
          </>
        ),
        startAdornment: (
          <StartAdornment>
            <GlobIcon className="glob-icon" />
            <span>
              <SearchIcon />
            </span>
          </StartAdornment>
        ),
      }}
      fullWidth
    />
  );
}
