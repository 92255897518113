import { Box, styled } from '@mui/material';
import { NavigationEntityKey } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectIsSessionExpired } from '../../../../app/appSlice';
import { useLoginRedirection } from '../../../../app/hooks/useLoginRedirection';
import { LoadingCard } from '../../../../components/LoadingCard';
import { useActiveSubscription } from '../../../../hooks/useActiveSubscription';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useGetOnboardingStateQuery } from '../../../inbox/features/onboarding/api';
import { useUserWarehouseDetails } from '../../../inbox/hooks/useUserWarehouseDetails';
import { useGetNavigationQuery } from '../../../inbox/inboxAccountsApi';
import { processLogout } from '../../authSlice';
import { useAuth } from '../../hooks/useAuth';

const LoadingCardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export function InitInbox() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { storeLoginRedirect, loginRedirect } = useLoginRedirection();

  const dispatch = useAppDispatch();
  const isSessionExpired = useAppSelector(selectIsSessionExpired);

  const navigationQuery = useGetNavigationQuery(null, {
    skip: !pathname.includes(inboxHelpers.MAIN_PATHS.APP) || !user,
  });
  const activeSubscriptionQuery = useActiveSubscription();
  const accountVerificationStateQuery = useGetOnboardingStateQuery(null, {
    skip: !user,
  });
  const warehouseDetailsQuery = useUserWarehouseDetails(user?.userUUID);
  const subscriptionFailure =
    activeSubscriptionQuery.error &&
    'status' in activeSubscriptionQuery.error &&
    activeSubscriptionQuery.error.status === 403;

  if (!user || subscriptionFailure) {
    if (!subscriptionFailure && !loginRedirect) {
      storeLoginRedirect();
    }
    dispatch(processLogout({}));
    return <Navigate to={inboxHelpers.MAIN_PATHS.LOGIN} replace />;
  }

  const isLoading = [
    accountVerificationStateQuery,
    navigationQuery,
    activeSubscriptionQuery,
    warehouseDetailsQuery,
  ].some((query) => query.isLoading || query.isUninitialized);

  if (isLoading) {
    return (
      <LoadingCardContainer>
        <LoadingCard title="Please wait while we set up your smart mailbox" />
      </LoadingCardContainer>
    );
  }

  if ((!activeSubscriptionQuery.activeSubscription || activeSubscriptionQuery.isError) && !isSessionExpired) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.CHECKOUT} replace />;
  }

  if (
    pathname.includes(inboxHelpers.APP_PATHS.VERIFY_ACCOUNT) &&
    navigationQuery.data &&
    !navigationQuery.data.find((item) => item.key === NavigationEntityKey.VerifyAccount)
  ) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.INBOX} />;
  }

  return <Outlet />;
}
