import { Theme } from '@mui/material/styles';
import { PUBLIC_HEADER_HEIGHT_LG, PUBLIC_HEADER_HEIGHT_MD } from './constants';
import { CSSProperties } from '@mui/material/styles/createMixins';

export const DEFAULT_SCROLL_BEHAVIOR = 'smooth';

export function scrollToElement(container: HTMLElement, elm: HTMLElement, behavior = DEFAULT_SCROLL_BEHAVIOR): void {
  const pos = getRelativePosition(elm);
  container.scrollTo({
    behavior: DEFAULT_SCROLL_BEHAVIOR,
    top: pos.top,
    left: pos.left,
  });
}

export function getRelativePosition(elm: HTMLElement): { top: number; right: number; bottom: number; left: number } {
  const parentElement = elm.parentElement;
  if (!parentElement) {
    throw new Error('Parent element not found');
  }

  const pPos = parentElement.getBoundingClientRect();
  const cPos = elm.getBoundingClientRect();

  const pos = {
    top: cPos.top - pPos.top + parentElement.scrollTop,
    right: cPos.right - pPos.right,
    bottom: cPos.bottom - pPos.bottom,
    left: cPos.left - pPos.left,
  };

  return pos;
}

export function createPublicPageStyles(theme: Theme): CSSProperties {
  return {
    padding: theme.spacing(3.75, 0, 3.75, 0),
    minHeight: `calc(100vh - ${PUBLIC_HEADER_HEIGHT_LG}px)`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      minHeight: `calc(100vh - ${PUBLIC_HEADER_HEIGHT_MD}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.paper,
    },
  };
}
