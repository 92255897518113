import { Box, styled } from '@mui/material';
import { FlexBox } from '../../FlexBox';
import { APP_BAR_HEIGHT } from '../../../constants';

export const GRID_MOBILE_HEADER_HEIGHT = 68;

export const DataGridHeaderContainer = styled(FlexBox, { shouldForwardProp: (prop) => prop !== 'stickyTop' })<{
  stickyTop?: number;
}>(({ theme, stickyTop = 2 * APP_BAR_HEIGHT }) => ({
  padding: theme.spacing(3, 4),
  minHeight: 80,
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: stickyTop,
  zIndex: 10,
  boxShadow: '0px 10px 20px 0px rgba(85, 85, 85, 0.1)',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 3),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2.5, 1, 2),
    boxShadow: '0px 10px 20px 0px rgba(85, 85, 85, 0.15)',

    zIndex: theme.zIndex.appBar - 1,
    height: GRID_MOBILE_HEADER_HEIGHT,
  },
}));
