import { Box, Stack, Typography, useTheme } from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { dateUtils, inboxHelpers, mailsShipmentUtils } from '@usgm/utils';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAppSettingsQuery } from '../../../../../../../../../app/api';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';

import { useGetShipInfoQuery } from '../../../../../../shipments/api';
import ActionButtons from '../../ActionButtons';
import StepHeader from '../StepHeader';

import { useActiveSubscriptionPlanDetails } from '../../hooks/useActiveSubscriptionPlanDetails';

import {
  mailsShipmentSlice,
  selectIsExpediteShipping,
  selectIsShipmentIncludesPackage,
  selectRequestedShipmentDate,
  selectSelectedRate,
  selectShippingDetails,
} from '../../mailsShipmentSlice';

import { DEFAULT_TIMEZONE, PICKUP_MAX_DAYS_TO_HOLD, PICKUP_NO_SHOW_FEE, PICKUP_SERVICE_TYPE } from '@inbox/constants';
import { MessageBox, Textarea, Preloader } from '@usgm/shared-ui';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { useUpsertShippingRequest } from '../../hooks/useUpsertShippingRequest';
import ExpediteShippingControl from './ExpediteShippingControl';
import IncludeNewItemsControl from './IncludeNewItemsControl';
import SignatureRequiredControl from './SignatureRequiredControl';

function DateStep() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isShipmentIncludesPackage = useAppSelector(selectIsShipmentIncludesPackage);

  const requestedShipmentDate = useAppSelector(selectRequestedShipmentDate);

  const {
    isConsolidationCostPermitted,
    shipmentId,
    instructions: defaultInstructions,
  } = useAppSelector(selectShippingDetails);

  const selectedRate = useAppSelector(selectSelectedRate);

  const [instructions, setInstructions] = useState(defaultInstructions);

  const { upsertQuery, upsertMailsShippingRequest } = useUpsertShippingRequest(instructions);

  const appSettingsQuery = useGetAppSettingsQuery();
  const shipInfoQuery = useGetShipInfoQuery();
  const { activeSubscriptionPlanQuery } = useActiveSubscriptionPlanDetails();

  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const isExpediteChecked = useAppSelector(selectIsExpediteShipping);
  const [showExpediteError, setShowExpediteError] = useState(false);

  const isExpediteAvailable =
    requestedShipmentDate &&
    appSettingsQuery.data?.enableExpediteShipping &&
    dateUtils.canExpediteShipmentNow(requestedShipmentDate);

  const shouldDisableDate = useCallback((date: DateTime<boolean>) => {
    return !dateUtils.isShipmentAvailableDate(date);
  }, []);

  const isFetchingData = appSettingsQuery.isLoading || shipInfoQuery.isLoading || activeSubscriptionPlanQuery.isLoading;

  const canProceed = !!requestedShipmentDate && !dateError && !isFetchingData && !upsertQuery.isLoading;

  const onDateChange = (date: DateTime<boolean> | null) => {
    if (date?.isValid) {
      dispatch(mailsShipmentSlice.actions.setRequestedShipmentDate(date.toISO()));
    }
  };

  const consolidationCost = mailsShipmentUtils.getConsolidationCost({
    isShipmentIncludesPackage,
    settings: activeSubscriptionPlanQuery.data?.shipment,
  });

  const errorMessage = useMemo(() => {
    switch (dateError) {
      case 'invalidDate': {
        return 'Please enter a valid date.';
      }
      case 'disablePast': {
        return 'Please select a future date.';
      }
      case 'shouldDisableDate': {
        return `Selected date falls on a weekend or a holiday. Please choose another date.`;
      }
      default: {
        return '';
      }
    }
  }, [dateError]);

  const handleInstructionsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(event.target.value);
  };

  const handleSubmit = () => {
    if (isExpediteAvailable && !isExpediteChecked) {
      setShowExpediteError(true);
      const elem = document.getElementById('expedite-shipment-checkbox');
      elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    upsertMailsShippingRequest({});
  };

  useEffect(() => {
    const closestValidDate = dateUtils.getClosestShipmentDate();
    dispatch(mailsShipmentSlice.actions.setRequestedShipmentDate(closestValidDate.toISO()));
  }, [dispatch]);

  const now = DateTime.now().setZone(DEFAULT_TIMEZONE);

  const showPickupWarning = selectedRate?.serviceType === PICKUP_SERVICE_TYPE;

  return (
    <>
      <ContentContainer pt={0} pb={11.75}>
        <StepHeader />
        <Box mt={4}>
          <MessageBox
            severity="info"
            title="Please note"
            description={`All shipment requests are converted to and processed based on US Central Daylight Time (CDT). For reference, the current CDT in the US is ${now
              .toFormat('h:mm a')
              .toLocaleLowerCase()} on ${now.toFormat('LLLL ')} ${inboxHelpers.numberWithSuffix(
              Number(now.toFormat('d')),
            )}.`}
          />
        </Box>
        <Stack mb={3} mt={6} direction="column" alignItems="stretch" spacing={1}>
          <Typography fontWeight={theme.typography.fontWeightBold}>Requested ship date</Typography>
          <DatePicker
            slotProps={{
              textField: {
                helperText: errorMessage,
              },
            }}
            onError={(error) => setDateError(error)}
            onChange={onDateChange}
            shouldDisableDate={shouldDisableDate}
            timezone={DEFAULT_TIMEZONE}
            disablePast
            value={requestedShipmentDate}
          />
        </Stack>
        {isFetchingData ? (
          <Preloader />
        ) : (
          <Box>
            <IncludeNewItemsControl />

            <SignatureRequiredControl />
            {isExpediteAvailable && (
              <Box id="expedite-shipment-checkbox">
                <ExpediteShippingControl
                  expeditePrice={shipInfoQuery.data?.shippingInfo.expeditePrice}
                  hasError={showExpediteError}
                />
              </Box>
            )}
            {isConsolidationCostPermitted && (
              <Box mt={3}>
                {isShipmentIncludesPackage ? (
                  <>
                    <Typography mb={0.5} fontWeight={theme.typography.fontWeightBold}>
                      Consolidation fee ({inboxHelpers.formatPrice(consolidationCost)}) is applied.
                    </Typography>
                    <Typography>
                      This helps us package your items compactly and securely into a sturdy box to reduce shipping cost
                      and protect them from damage and breakage.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography mb={1} fontWeight={theme.typography.fontWeightBold}>
                      Bundle fee ({inboxHelpers.formatPrice(consolidationCost)}) is applied.
                    </Typography>
                    <Typography>Note: multiple items in shipment.</Typography>
                  </>
                )}
              </Box>
            )}
            {showPickupWarning && (
              <Box mt={3}>
                <MessageBox
                  severity="warning"
                  description={`Your pick-up request will be held for  ${PICKUP_MAX_DAYS_TO_HOLD} days; ${inboxHelpers.formatPrice(
                    PICKUP_NO_SHOW_FEE,
                  )} fee applies for no-shows.`}
                />
              </Box>
            )}
            <Box mt={3}>
              <Typography mb={1} fontWeight={theme.typography.fontWeightBold}>
                Packing Instructions
              </Typography>
              <Textarea
                resize="none"
                onChange={handleInstructionsChange}
                value={instructions}
                defaultValue={defaultInstructions}
                fullWidth
                placeholder="Tell us how you want the items to be packed"
              />
            </Box>
          </Box>
        )}
      </ContentContainer>
      <ActionButtons
        nextLabel={shipmentId ? 'Update' : 'Submit'}
        isLoading={upsertQuery.isLoading}
        onNextClick={handleSubmit}
        canProceed={canProceed}
        errorMessage={errorMessage || 'Please select a date to continue'}
      />
    </>
  );
}

export default DateStep;
